<template>
  <div class="unsubscribe-view-talent__wrapper">
    <transition name="fade">
      <div class="container" v-if="selectedVeiw == 'form'">
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="talent-inner-box profile-card">
              <h1>
                {{$t('talent.unsubscribeFromOurList')}}
              </h1>
              <p>
              

                {{$t('talent.weAreSorryToSeeYouGoPleaseHelpUsUnderstandWhyYouChoseNotToReceiveAnyEmails')}}
              </p>
              <div class="row">
                <div
                  class="form-check d-flex mb-3"
                  v-for="(option, i) in options"
                  :key="i"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    :id="option.value"
                    v-model="selected"
                    :value="option.value"
                  />
                  <label class="form-check-label" :for="option.value">
                     {{$t(`talent.${[option.value]}`) }}
                  </label>
                </div>
                <div
                  class="mb-3 p-0"
                  v-if="selected == 'otherFillInTheReasonBelow'"
                >
                  <textarea
                    class="form-control p-3"
                    :placeholder="$t('talent.pleaseWriteYourComment')"
                    rows="2"
                    v-model="$v.comments.$model"
                  ></textarea>
                  <div class="mt-1">
                    <VulidateError
                      :validationField="$v.comments"
                      :errorMsg="
                        !$v.comments.required
                          ? $t('talent.pleaseWriteYourComment')
                          : !$v.comments.maxLength
                          ? $t('talent.commentShouldBe500CharacterMaximum')
                          : ''
                      "
                      :params="['required', { maxLength: { number: '500' } }]"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="applyViewBtnWrapper">
                  <div class="">
                    <button @click="cancel" type="button" class="btn cancelbtn">
                      
                      {{$t('talentWizard.Cancel')}}
                    </button>
                  </div>
                  <div class="baseBtn">
                    <button
                      @click="submit"
                      :disabled="submitState"
                      type="button"
                      class="btn btnstyleUpdate btnSecondary"
                    >
                      {{$t('talent.unsubscribe')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-if="selectedVeiw == 'thanks'">
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="talent-inner-box profile-card">
              <h1>
               {{$t('talent.successfullyUnsubscribe')}}
              </h1>
              <p v-if="false">
               

                 {{$t('talent.weAreSorryToSeeYouGoPleaseHelpUsUnderstandWhyYouChoseNotToReceiveAnyEmails')}}
              </p>

              <img
                class="feedback-img"
                src="@/assets/images/talent/unsubcribed/Feedback.svg"
              />

              <h2>
                 {{$t('talent.thankYouForYourFeedback')}}
             </h2>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Steps from "../../../enums/WizzardSteps";
import VulidateError from "../../../components/shared/VulidateError/vulidateError.vue";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  components: {
    VulidateError,
  },
  validations: {
    comments: {
      required,
      maxLength: maxLength(500),
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      profile: "talentProfile/getProfileData",
    }),
  },
  created() {
    if (this.profile.basicInfo.unsubscribed) {
      this.selectedVeiw = "thanks";
    }
  },
  data() {
    return {
      selectedVeiw: "form",
      submitState: false,
      comments: "",
      selected: "theEmailsAreNotRelevantToMe",
      options: [
        { value: "theEmailsAreNotRelevantToMe" },
        { value: "iAlreadyHaveAJob" },
        { value: "theEmailsAreTooFrequent" },
        { value: "iFindTheEmailsInappropriateOrSpam" },
        { value: "otherFillInTheReasonBelow)" },
      ],
    };
  },
  methods: {
    cancel() {
      this.user.step == Steps.PROFILE
        ? this.$router.push({ path: "/sq/talent/profile" })
        : this.$router.push({ name: "Wizard" });
    },
    submit() {
      let payload = {
        user_id: this.user.id,
        email_type: "nomination",
        reason: "",
      };
      if (this.selected == "otherFillInTheReasonBelow") {
        this.$v.comments.$touch();
        if (this.$v.$anyError) {
          return;
        }

        payload.reason = this.comments;
      } else {
        payload.reason = this.selected;
      }

      this.submitState = true;
      this.$store
        .dispatch("talentProfile/unsubscribe", payload)
        .then(() => {
          this.selectedVeiw = "thanks";
        })
        .catch(() => {})
        .finally(() => {
          this.submitState = false;
        });
    },
  },
};
</script>


<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: 0.5s;
}

.unsubscribe-view-talent__wrapper .container {
  max-width: 1040px;
}

.unsubscribe-view-talent__wrapper .radio-toolbar label {
  padding-right: 2rem;
  padding-left: 2rem;
  border-radius: 15px;
  border: 1px solid #8d8d8d;
  color: #8d8d8d;
}

.unsubscribe-view-talent__wrapper .ml-2 {
  margin-right: 1rem;
}

.unsubscribe-view-talent__wrapper
  .radio-toolbar
  input[type="checkbox"]:checked
  + label {
  background-color: rgb(173 162 209 / 8%);
  border: 1px solid rgb(66, 46, 135);
  border-radius: 4px;
  color: rgb(66, 46, 135);
  border-radius: 15px;
  font-weight: 600;
}

.unsubscribe-view-talent__wrapper .mb-8 {
  margin-bottom: 3rem;
}

.unsubscribe-view-talent__wrapper .applyViewBtnWrapper {
  margin-top: 3rem;
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.unsubscribe-view-talent__wrapper .feedback-img {
  height: 12rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.unsubscribe-view-talent__wrapper h1 {
  font-weight: 700;
  font-size: 2rem;
}

.unsubscribe-view-talent__wrapper p {
  font-size: 18px;
  width: 75%;
  color: #a6a6a6;
}

.unsubscribe-view-talent__wrapper .cancelbtn {
  color: #ffc629;
  border: 2px solid #ffc629;
  font-weight: 700;
  border-radius: 8px !important;
}

.unsubscribe-view-talent__wrapper .cancelbtn:hover {
  color: #ffc629;
  border: 2px solid #ffc629;
  font-weight: 700;
  border-radius: 8px !important;
}

.unsubscribe-view-talent__wrapper .talent-inner-box {
  padding: 60px 80px;
}

@media (max-width: 425px) {
  .unsubscribe-view-talent__wrapper .talent-inner-box {
    padding: 18px !important;
  }
}

.unsubscribe-view-talent__wrapper .form-check {
  padding-left: 2em;
}

.unsubscribe-view-talent__wrapper .form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle fill='rgb(255 198 41)'  r='2.5' /%3e%3c/svg%3e");
}

.unsubscribe-view-talent__wrapper .form-check-input {
  width: 2em;
  height: 2em;
}

.unsubscribe-view-talent__wrapper .btn {
  padding: 12px 30px;
  border-radius: 8px;
  margin-left: 1rem;
  font-weight: 600;
}

.unsubscribe-view-talent__wrapper label {
  margin-top: 6px;
  margin-left: 8px;
  color: #504e4e;
}

.unsubscribe-view-talent__wrapper .form-check-input:focus {
  border-color: rgb(255 198 41 / 25%);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(255 198 41 / 25%);
}

.unsubscribe-view-talent__wrapper .form-check-input:checked {
  background-color: #fff;
  border-color: rgb(255 198 41);
}

.unsubscribe-view-talent__wrapper .radio-toolbar {
  flex-flow: wrap;
}

.unsubscribe-view-talent__wrapper .skill-check {
  display: none;
}

.unsubscribe-view-talent__wrapper .btnSecondary,
.btnSecondary:disabled {
  background-color: #ffc629 !important;
  border: 2px solid #ffc629;
  font-weight: 600;
}

.unsubscribe-view-talent__wrapper ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #878484;

  opacity: 1; /* Firefox */
}

.unsubscribe-view-talent__wrapper :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #878484;
}

.unsubscribe-view-talent__wrapper ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #878484;
}
</style>
